import React from 'react';
import { useLocation } from '@reach/router';
import { ILocalFile } from '../model/graphql-types';
import { getSrc } from 'gatsby-plugin-image';

interface IProps {
  title?: string;
  description?: string;
  game?: string;
  image?: {
    localFile?: ILocalFile;
  } | null;
}

export const Seo: React.FC<IProps> = ({ title, description, image, game }) => {
  const currentPath = useLocation().pathname;
  let pageTitle = title || 'Prydwen Institute';
  const pageDescription =
    description ||
    'Prydwen.gg provides news, tier lists and detailed guides for Honkai: Star Rail, Counter Side, NIKKE, Reverse:1999 and more gacha games. Everything you need in one place!';
  if (currentPath === '/') {
    pageTitle =
      'Prydwen Institute - the best source of guides for your favorite gacha games';
  }

  const baseUrl = 'https://www.prydwen.gg';
  const fullUrl = baseUrl + currentPath;

  let seoImageUrl = null;
  if (image) {
    seoImageUrl = baseUrl + getSrc(image.localFile.childImageSharp);
  } else {
    if (game) {
      if (game === 'reverse') {
        seoImageUrl = `${baseUrl}/social_r99.jpg`;
      } else if (game === 'nikke') {
        seoImageUrl = `${baseUrl}/social_nikke.jpg`;
      } else if (game === 'cs') {
        seoImageUrl = `${baseUrl}/social_cs.jpg`;
      } else if (game === 'limbus') {
        seoImageUrl = `${baseUrl}/social_limbus.jpg`;
      } else if (game === 'eversoul') {
        seoImageUrl = `${baseUrl}/social_eversoul.jpg`;
      } else if (game === 'tog') {
        seoImageUrl = `${baseUrl}/social_tog.jpg`;
      } else if (game === 'zzz') {
        seoImageUrl = `${baseUrl}/social_zzz.jpg`;
      } else if (game === 'bcm') {
        seoImageUrl = `${baseUrl}/social_bcm.jpg`;
      } else if (game === 'afk') {
        seoImageUrl = `${baseUrl}/social_afk.jpg`;
      } else if (game === 'endfield') {
        seoImageUrl = `${baseUrl}/social_endfield.jpg`;
      } else if (game === 'exilium') {
        seoImageUrl = `${baseUrl}/social_exilium.jpg`;
      } else if (game === 'ww') {
        seoImageUrl = `${baseUrl}/social_ww.jpg`;
      } else if (game === 'solo') {
        seoImageUrl = `${baseUrl}/social_solo.jpg`;
      } else if (game === 'ash') {
        seoImageUrl = `${baseUrl}/social_ash.jpg`;
      }
    } else {
      seoImageUrl = `${baseUrl}/social_sharing_n.jpg`;
    }
  }

  return (
    <>
      <meta charSet="utf-8" />
      <title>{pageTitle}</title>
      <link rel="canonical" href={fullUrl} />
      <meta name="description" content={pageDescription} />
      <meta name="title" content={pageTitle} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={fullUrl} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:image" content={seoImageUrl} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={fullUrl} />
      <meta property="twitter:title" content={pageTitle} />
      <meta property="twitter:description" content={pageDescription} />
      <meta property="twitter:image" content={seoImageUrl} />
    </>
  );
};
